<template>
  <v-app-bar v-if="isAuth" flat app class="white lighten-4">
    <v-app-bar-nav-icon @click.stop="openDrawer((drawerMenu = !drawerMenu))" />
    <v-toolbar-title
      >{{ title }} <strong class="caption"> (v {{ version }})</strong></v-toolbar-title
    >
    <v-spacer />
    <v-menu min-width="300" offset-y>
      <template #activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-avatar color="red">
            <span class="white--text headline text-uppercase">{{
              isProfile.username.substring(0, 2)
            }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list rounded>
        <v-subheader>User Profile</v-subheader>
        <v-row class="ml-10">
          <h4>Name : {{ isProfile.fullName }}</h4>
        </v-row>
        <v-row class="ml-10 mb-2">
          <v-chip small color="#6FCF97">
            {{ isProfile.departmentName }}
          </v-chip>
        </v-row>
        <v-row class="ml-10 mb-2">
          <v-chip small color="#F2994A">
            {{ isProfile.rolesDescription[0].name }}
          </v-chip>
        </v-row>
        <v-divider />
        <v-list-item-group>
          <v-list-item dense @click="userLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- <v-avatar class="mr-3" /> -->
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import { deleteCookie } from '../../helpers/util';
// import conf from '../../helpers/config';
// import getEnv from '../../helpers/env';

export default {
  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTHENTICATED',
      isProfile: 'auth/GET_PROFILE'
    })
  },

  data() {
    return {
      title: 'TRUE DTAC MESSAGE CENTER', // conf.url.VUE_APP_TITLE || process.env.VUE_APP_TITLE,
      version: '0.2.14' // conf.url.VUE_APP_VERSION || process.env.VUE_APP_VERSION,
    };
  },

  methods: {
    async userLogout() {
      this.$store.dispatch('auth/userLogout');
      deleteCookie('acs-token');

      this.$router.push('/login');
    }
  }
};
</script>

<style></style>
